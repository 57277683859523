import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../Projects/ProjectCards";
import Particle from "../Particle";
import AOS from "aos";
import "aos/dist/aos.css";

function Certifications() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Certifications </strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card"  data-aos="zoom-in" data-aos-duration="2950">
            <ProjectCard
              imgPath={"https://miro.medium.com/v2/resize:fit:1200/1*Y5-c8kiEfGc23KM__zwmNA.gif"}
              isBlog={true}
              title="Amazon Cloud Practitioner"
              description= {<div>
                <div style={{display:"inline-block"}}>
                <label>Date Issused:&nbsp; &nbsp; </label>
                <span>2023-06-20</span>
                </div>
                <div style={{display:"inline-block"}}>
                <label>Expiration Date:&nbsp; &nbsp;  </label>
                <span>2026-11-30</span>
                </div>
                <div style={{display:"inline-block"}}>
                <label>Validation Number:&nbsp; &nbsp;  </label>
                <span>L8YM45H1XE141WGQ</span>
                <div style={{position:"absolute",top:"10px",right:"10px"}}>
                  <img src="https://images.credly.com/size/220x220/images/00634f82-b07f-4bbd-a6bb-53de397fc3a6/image.png" alt="" width={80} height={80} className="rotate"/>
                </div>
                </div>
              </div>

              }
              ghLink="https://aws.amazon.com/verification"
              demoLink="https://appcq.vercel.app"
            />
          </Col>

          <Col md={4} className="project-card"  data-aos="zoom-in" data-aos-duration="2950">
            <ProjectCard
              imgPath={"https://miro.medium.com/v2/resize:fit:1200/1*Y5-c8kiEfGc23KM__zwmNA.gif"}
              isBlog={true}
              title="Amazon Developer Associate"
              description= {<div>
                <div style={{display:"inline-block"}}>
                <label>Date Issused:&nbsp; &nbsp; </label>
                <span>2023-11-30</span>
                </div>
                <div style={{display:"inline-block"}}>
                <label>Expiration Date:&nbsp; &nbsp;  </label>
                <span>2026-11-30</span>
                </div>
                <div style={{display:"inline-block"}}>
                <label>Validation Number:&nbsp; &nbsp;  </label>
                <span>KFMKVE1L5J1EQPW3</span>
                <div style={{position:"absolute",top:"10px",right:"10px"}}>
                  <img src="https://images.credly.com/size/220x220/images/b9feab85-1a43-4f6c-99a5-631b88d5461b/image.png" alt="" width={80} height={80} className="rotate"/>
                </div>
                </div>
              </div>

              }
              ghLink="https://aws.amazon.com/verification"
              demoLink="https://appcq.vercel.app"
            />
          </Col>

          <Col md={4} className="project-card img-fluid"  data-aos="zoom-in" data-aos-duration="2950">
            <ProjectCard
              imgPath={ "https://www.redhat.com/rhdc/managed-files/Logo_Change.gif"}
              isBlog={true}
              title="RedHat [EX-183]"
              description= {<div>
                <div style={{display:"inline-block"}}>
                <label>Date Issused:&nbsp; &nbsp; </label>
                <span>2023-08-10</span>
                </div>
                <div style={{display:"inline-block"}}>
                <label>Expiration Date:&nbsp; &nbsp;  </label>
                <span>2026-08-10</span>
                </div>
                <div style={{display:"inline-block"}}>
                <label>Validation Number:&nbsp; &nbsp;  </label>
                <span>230-177-735</span>
                <div style={{position:"absolute",top:"10px",right:"10px"}}>
                  <img src="https://static-00.iconduck.com/assets.00/distributor-logo-redhat-icon-2048x2048-lans3pny.png" alt="" width={80} height={80} className="rotate"/>
                </div>
                </div>
              </div>

              }
              ghLink="https://www.credly.com/badges/21679376-8311-4f0b-b5ac-ecf3f374626e"
              demoLink="https://appcq.vercel.app"
            />
          </Col>

          <Col md={4} className="project-card img-fluid"  data-aos="zoom-in" data-aos-duration="2950">
            <ProjectCard
              imgPath={"https://res.cloudinary.com/ddkdeeue3/image/upload/v1709373353/msnfgqt9ng42unhr52ym.png"}
              isBlog={true}
              title="Java Basic"
              description= {<div style={{position:"relative"}}>
                <div style={{display:"inline-block"}}>
                <label>Date Issused:&nbsp; &nbsp; </label>
                <span>2023-03-18</span>
                </div>
                <div style={{display:"inline-block"}}>
                <label>Expiration Date:&nbsp; &nbsp;  </label>
                <span>LifeTime</span>
                <div style={{position:"absolute",top:"10px",right:"10px"}}>
                  <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/HackerRank_logo.png/800px-HackerRank_logo.png" alt="" width={80} height={80} className="rotate"/>
                </div>
                </div>
              </div>

              }
              ghLink="https://www.hackerrank.com/certificates/02a200d64202"
              demoLink="https://appcq.vercel.app"
            />
          </Col>

          <Col md={4} className="project-card img-fluid"  data-aos="zoom-in" data-aos-duration="2950">
            <ProjectCard
              imgPath={"https://assets.leetcode.com/static_assets/marketing/2023-50.gif"}
              isBlog={true}
              title="LeetCode 50 Days"
              description= {<div style={{position:"relative"}}>
                <div style={{display:"inline-block"}}>
                <label>Date Issused:&nbsp; &nbsp; </label>
                <span>2023-12-18</span>
                </div>
                <div style={{display:"inline-block"}}>
                <label>Expiration Date:&nbsp; &nbsp;  </label>
                <span>LifeTime</span>
                <div style={{position:"absolute",top:"10px",right:"10px"}}>
                  <img src="https://upload.wikimedia.org/wikipedia/commons/1/19/LeetCode_logo_black.png" alt="" width={80} height={80} className="rotate"/>
                </div>
                </div>
              </div>
              }
              ghLink="https://leetcode.com/KLU_2100031268/"
              demoLink="https://appcq.vercel.app"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Certifications;
