import React, { useEffect } from "react";
import { Col, Row} from "react-bootstrap";
import {Tooltip } from 'antd'
import AOS from "aos";
import "aos/dist/aos.css";
import {
  SiVisualstudiocode,
  SiPostman,
  SiVercel,
  SiRender,
  SiRazorpay,
  SiEclipseide
} from "react-icons/si";

function Toolstack() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Tooltip title="Visual Studio Code">
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-right">
        <SiVisualstudiocode />
      </Col>
      </Tooltip>
      <Tooltip title="Eclipse IDE">
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-right">
        <SiEclipseide />
      </Col>
      </Tooltip>
      <Tooltip title="Postman">
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-right">
        
        <SiPostman />
      </Col>
      </Tooltip>
      <Tooltip title="Vercel">
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-right">
        <SiVercel />
      </Col>
      </Tooltip>
      <Tooltip title="Render">
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-right">
        <SiRender />
      </Col>
      </Tooltip>
      <Tooltip title="Razorpay">
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-right">
        <SiRazorpay />
      </Col>
      </Tooltip>
    </Row>
  );
}

export default Toolstack;
