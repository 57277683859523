import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import AOS from "aos";
import {Tooltip} from 'antd'
import "aos/dist/aos.css";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiGit,
  DiJava,
  DiAws,
  DiBootstrap
} from "react-icons/di";
import {
  SiMysql,
  SiHtml5,
  SiCss3,
  SiAliexpress,
  SiSpringboot,
  SiAntdesign,
  SiAmazonaws
} from "react-icons/si";

function Techstack() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Tooltip title='Java'>
       <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-right" data-aa0s-duration="3000">
        <DiJava />
      </Col>
      </Tooltip>
      <Tooltip title='C++'>
      <Col xs={4} md={2} className="tech-icons" >
        <CgCPlusPlus />
      </Col>
      </Tooltip>
      <Tooltip title='Html5'>
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-right" data-aa0s-duration="3000">
        <SiHtml5 />
      </Col>
      </Tooltip>
      <Tooltip title='Css3'>
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-right" data-aa0s-duration="3000">
        < SiCss3/>
      </Col>
      </Tooltip>
      <Tooltip title='JavaScript'>
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-left" data-aa0s-duration="3000">
        <DiJavascript1 />
      </Col>
      </Tooltip>
      <Tooltip title='ANT Design'>
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-right" data-aa0s-duration="3000">
        < SiAntdesign/>
      </Col>
      </Tooltip>
      <Tooltip title='BootStrap'>
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-left">
        <DiBootstrap />
      </Col>
      </Tooltip>
      <Tooltip title='React Js'>
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-left">
        <DiReact />
      </Col>
      </Tooltip>
      <Tooltip title='Spring Boot'>
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-left">
        <SiSpringboot />
      </Col>
      </Tooltip>
      <Tooltip title='Node Js'>
            <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-left">
        <DiNodejs />
      </Col>
      </Tooltip>
      <Tooltip title='Mongodb'>
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-right">
        <DiMongodb />
      </Col>
      </Tooltip>

      <Tooltip title='Git'>
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-right">
        <DiGit />
      </Col>
      </Tooltip>

      <Tooltip title='AWS'>
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-right">
        <SiAmazonaws />
      </Col>
      </Tooltip>

      <Tooltip title='MYSQL'>
      <Col xs={4} md={2} className="tech-icons" data-aa0s="flip-right">
        <SiMysql />
      </Col>
      </Tooltip>
    </Row>
  );
}

export default Techstack;
